exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-about-index-tsx": () => import("./../../../src/pages/about/index.tsx" /* webpackChunkName: "component---src-pages-about-index-tsx" */),
  "component---src-pages-about-our-company-tsx": () => import("./../../../src/pages/about/OurCompany.tsx" /* webpackChunkName: "component---src-pages-about-our-company-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-products-and-services-aws-cloud-tsx": () => import("./../../../src/pages/products-and-services/AWSCloud.tsx" /* webpackChunkName: "component---src-pages-products-and-services-aws-cloud-tsx" */),
  "component---src-pages-products-and-services-chrome-icon-tsx": () => import("./../../../src/pages/products-and-services/ChromeIcon.tsx" /* webpackChunkName: "component---src-pages-products-and-services-chrome-icon-tsx" */),
  "component---src-pages-products-and-services-cicd-icon-tsx": () => import("./../../../src/pages/products-and-services/CICDIcon.tsx" /* webpackChunkName: "component---src-pages-products-and-services-cicd-icon-tsx" */),
  "component---src-pages-products-and-services-firefox-icon-tsx": () => import("./../../../src/pages/products-and-services/FirefoxIcon.tsx" /* webpackChunkName: "component---src-pages-products-and-services-firefox-icon-tsx" */),
  "component---src-pages-products-and-services-gcp-icon-tsx": () => import("./../../../src/pages/products-and-services/GCPIcon.tsx" /* webpackChunkName: "component---src-pages-products-and-services-gcp-icon-tsx" */),
  "component---src-pages-products-and-services-index-tsx": () => import("./../../../src/pages/products-and-services/index.tsx" /* webpackChunkName: "component---src-pages-products-and-services-index-tsx" */),
  "component---src-pages-products-and-services-mobile-app-icon-tsx": () => import("./../../../src/pages/products-and-services/MobileAppIcon.tsx" /* webpackChunkName: "component---src-pages-products-and-services-mobile-app-icon-tsx" */),
  "component---src-pages-products-and-services-play-store-icon-tsx": () => import("./../../../src/pages/products-and-services/PlayStoreIcon.tsx" /* webpackChunkName: "component---src-pages-products-and-services-play-store-icon-tsx" */),
  "component---src-pages-what-we-think-index-tsx": () => import("./../../../src/pages/what-we-think/index.tsx" /* webpackChunkName: "component---src-pages-what-we-think-index-tsx" */)
}

